import { Metadata } from "next";
import { productTransformer } from "../transformers/getProduct";
import { getProductById } from "./get-products-helpers";
import { amplifyGet } from "@utils/fetch";
import { Participant, ProductCategory } from "@models/models";
const striptags = require("striptags");

export async function generateProductMetadata(
  productId: string,
): Promise<Metadata> {
  const product = await getProductById(productId);
  const comics = product.variants.flatMap((v) =>
    v.variantParticipants.flatMap((vp) => vp.participant?.name),
  );

  return {
    title: product.title,
    description: product.description,
    alternates: {
      canonical: `https://www.jumpcomedy.com/e/${product.handle}`,
    },
    openGraph: {
      title: product.title,
      description: striptags(product.description as string),
      type: "website",
      url: `https://www.jumpcomedy.com/e/${product.handle}`,
      images: [
        {
          url: product.thumbnail as string,
        },
      ],
    },
    keywords: [
      "comedy",
      "shows",
      product.venueArrangement
        ? product.venueArrangement.venue.name
        : "gift cards",
    ].concat(comics),
    robots: {
      index: true,
      follow: true,
      googleBot: {
        index: true,
        follow: true,
      },
    },
  };
}

function getSlugDescription(obj: any): string {
  if ("description" in obj && (obj as ProductCategory).description) {
    return obj.description;
  } else if ("profile" in obj && (obj as Participant).profile) {
    return obj.profile;
  }
  return obj.name;
}

export async function generateSlugEntityMetadata(
  slug: string,
): Promise<Metadata> {
  const slugEntity: { name: string } = await amplifyGet(`/store/slugs/${slug}`);
  return {
    title: slugEntity.name,
    description: getSlugDescription(slugEntity),
    keywords: ["comedy", "shows", slugEntity.name],
    robots: {
      index: true,
      follow: true,
      googleBot: {
        index: true,
        follow: true,
      },
    },
  };
}
