"use client";
import React, { useEffect } from "react";
import { useAppDispatch } from "@store/store";
import { usePathname } from "next/navigation";
import { initializeLocation } from "@utils/product-page-utils";
import { fetchCart } from "@store/cart-slice";
import { clearMessages } from "@store/messages-slice";
import { Toaster } from "react-hot-toast";
import Spinner from "@components/spinner";

export default function AppWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const pathname = usePathname();

  useEffect(() => {
    const load = async () => {
      await initializeLocation();
      dispatch(fetchCart());
    };
    load();
  }, []);

  useEffect(() => {
    dispatch(clearMessages());
  }, [pathname, dispatch]);
  return (
    <>
      <Toaster />
      <Spinner />

      {children}
    </>
  );
}
