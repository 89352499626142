import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/app-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/roboto/300.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/roboto/400.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/roboto/500.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/roboto/700.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/store/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
